<template>
  <div>
    <b-card
      no-body
    >
      <div class="m-2">
        <b-row>
          <b-col
            v-if="type === 'tanggal'"
            cols="12"
            md="12"
          >
            <b-form-group
              label="Tanggal"
              label-for="tanggal-booking"
              label-cols-md="1"
            >
              <VDatePicker
                v-model="data.tanggal"
                :attributes="attributes"
                :disabled-dates="disabledDates"
                is-range
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="type === 'tanggal'"
            cols="12"
            md="12"
          >
            <b-form-group
              label="Nama Bis"
              label-for="bus-booking"
              label-cols-md="1"
            >
              <b-form-select
                id="bus-booking"
                v-model="data.id_bis"
                :disabled="data.tanggal === null"
                :options="optionsBis"
                placeholder="Bis"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="type === 'bis'"
            cols="12"
            md="12"
          >
            <b-form-group
              label="Nama Bis"
              label-for="bus-booking"
              label-cols-md="1"
            >
              <b-form-select
                id="bus-booking"
                v-model="data.id_bis"
                :options="optionsBis"
                placeholder="Bis"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="12"
          >
            <b-form-group
              label="Nama Supir"
              label-for="supir-booking"
              label-cols-md="1"
            >
              <b-form-select
                id="supir-booking"
                v-model="data.id_supir"
                :options="optionsSupir"
                placeholder="Supir"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="12"
          >
            <b-form-group
              label="Nama Kernet"
              label-for="kernet-booking"
              label-cols-md="1"
            >
              <b-form-select
                id="kernet-booking"
                v-model="data.id_kernet"
                :options="optionsKernet"
                placeholder="kernet"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="12"
          >
            <b-form-group
              label="Customer"
              label-for="customer-booking"
              label-cols-md="1"
            >
              <b-form-input
                id="customer-booking"
                v-model="data.customer"
                placeholder="Nama Customer"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="12"
          >
            <b-form-group
              label="Tujuan"
              label-for="tujuan-booking"
              label-cols-md="1"
            >
              <b-form-input
                id="tujuan-booking"
                v-model="data.tujuan"
                placeholder="Tujuan"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="12"
          >
            <b-form-group
              label="Titik Penjemputan"
              label-for="penjemputan-booking"
              label-cols-md="2"
            >
              <b-form-input
                id="penjemputan-booking"
                v-model="data.titik_penjemputan"
                placeholder="Titik Penjemputan"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="12"
          >
            <b-form-group
              label="Rute"
              label-for="rute-booking"
              label-cols-md="2"
            >
              <b-form-input
                id="rute-booking"
                v-model="data.rute"
                placeholder="Rute"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="12"
          >
            <b-form-group
              label="Alamat Lengkap Penjemputan"
              label-for="alamat-lengkap-booking"
              label-cols-md="2"
            >
              <b-form-input
                id="alamat-lengkap-booking"
                v-model="data.alamat_lengkap_penjemputan"
                placeholder="Alamat Lengkap Penjemputan"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="12"
          >
            <b-form-group
              label="Link Google Maps Penjemputan"
              label-for="link-maps-booking"
              label-cols-md="2"
              description="Contoh (https://maps.app.goo.gl/e4PxfUviuY5Gbgb49) - Masukkan (e4PxfUviuY5Gbgb49)"
            >
              <b-input-group prepend="https://maps.app.goo.gl/">
                <b-form-input
                  id="link-maps-booking"
                  v-model="data.link_gmaps"
                  placeholder="e4PxfUviuY5Gbgb49"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="12"
          >
            <b-form-group
              label="Keterangan"
              label-for="keterangan-booking"
              label-cols-md="1"
            >
              <b-form-textarea
                id="keterangan-booking"
                v-model="data.keterangan"
                placeholder="Keterangan"
                rows="4"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="12"
          >
            <b-form-group
              label="Jenis Pembayaran"
              label-for="pembayaran-booking"
              label-cols-md="1"
            >
              <b-form-select
                id="pembayaran-booking"
                v-model="data.status_pembayaran"
                :options="optionsPembayaran"
                placeholder="Bis"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="12"
          >
            <b-form-group
              label="Total Harga"
              label-for="harga-booking"
              label-cols-md="1"
            >
              <b-input-group prepend="RP">
                <b-form-input
                  id="harga-booking"
                  v-model="data.harga"
                  type="number"
                  placeholder="500000"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col
            v-if="data.status_pembayaran === 'dp'"
            cols="12"
            md="12"
          >
            <b-form-group
              label="DP"
              label-for="dp-booking"
              label-cols-md="1"
            >
              <b-input-group prepend="RP">
                <b-form-input
                  id="dp-booking"
                  v-model="data.jumlah_pembayaran_dp"
                  type="number"
                  placeholder="10000"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col
            v-if="data.status_pembayaran === 'dp' || data.status_pembayaran === 'belum_bayar'"
            cols="12"
            md="12"
          >
            <b-form-group
              label="Kekurangan"
              label-for="kekurangan-booking"
              label-cols-md="1"
            >
              <b-input-group prepend="RP">
                <b-form-input
                  id="kekurangan-booking"
                  v-model="kekurangan"
                  type="number"
                  readonly
                  placeholder="200000"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col
            v-if="type === 'bis'"
            cols="12"
            md="12"
          >
            <b-form-group
              label="Tanggal"
              label-for="tanggal-booking"
              label-cols-md="1"
            >
              <!-- <date-picker
                v-model="data.tanggal"
                value-type="format"
                format="YYYY-MM-DD"
                type="date"
                range
                placeholder="Pilih Tanggal Berangkat dan Pulang"
              /> -->
              <VDatePicker
                v-model="data.tanggal"
                :attributes="attributes"
                :disabled-dates="disabledDates"
                is-range
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="12"
          >
            <b-form-group
              label="Waktu Penjemputan"
              label-for="waktu-penjemputan-booking"
              label-cols-md="2"
            >
              <date-picker
                v-model="data.waktu_penjemputan"
                value-type="format"
                format="HH:mm:ss"
                type="time"
                placeholder="Pilih Jam Penjemputan"
              />
            </b-form-group>
          </b-col>
          <!-- <b-col
            v-if="type === 'bis'"
            cols="12"
            md="12"
          >
            <b-form-group
              label="Tanggal Balik"
              label-for="tanggal-booking"
              label-cols-md="1"
            >
              <flat-pickr
                id="tanggal-booking"
                v-model="data.tanggal_balik"
                class="form-control"
                :config="{ inline: true}"
              />
            </b-form-group>
          </b-col> -->
        </b-row>
        <b-button
          variant="primary"
          @click="addBooking()"
        >
          Submit
        </b-button>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BFormSelect, BFormInput, BFormTextarea, BInputGroup, BButton,
  VBToggle,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BFormInput,
    BFormTextarea,
    BInputGroup,
    BButton,

    DatePicker,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      data: {
        customer: null,
        tujuan: null,
        titik_penjemputan: null,
        rute: null,
        keterangan: null,
        status_pembayaran: null,
        tanggal: null,
        harga: 0,
        jumlah_pembayaran_dp: 0,
        id_bis: null,
        id_supir: null,
        id_kernet: null,
        link_gmaps: null,
        link_gmaps_penjemputan: null,
        waktu_penjemputan: null,
        alamat_lengkap_penjemputan: null,
      },
      type: this.$route.params.type,
      optionsBis: [],
      optionsSupir: [],
      optionsKernet: [],
      optionsPembayaran: [
        {
          value: 'lunas',
          text: 'Lunas',
        },
        {
          value: 'dp',
          text: 'DP',
        },
        {
          value: 'belum_bayar',
          text: 'Belum',
        },
      ],
      tanggalMerah: [],
      disabledDates: [],
    }
  },
  computed: {
    kekurangan() {
      return this.data.harga - this.data.jumlah_pembayaran_dp
    },
    attributes() {
      return [
        // Attributes for tanggalMerah
        ...this.tanggalMerah.map(todo => ({
          dates: todo.dates,
          dot: {
            color: todo.color,
            class: 'opacity-75',
          },
          popover: {
            label: todo.description,
          },
          customData: todo,
        })),
      ]
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'data.id_bis': function (newValue) {
      if (this.type === 'bis') {
        this.$http.get(`/tanggal-merah-bis/${newValue}`).then(response => {
          this.disabledDates = response.data
        })
      }
    },
    // eslint-disable-next-line func-names
    'data.tanggal': function (newValue) {
      if (this.type === 'tanggal') {
        const data = {
          tanggal_berangkat: newValue.start.toISOString().split('T')[0],
          tanggal_balik: newValue.end.toISOString().split('T')[0],
        }
        this.$http.post('/booking/cek-bis-by-tgl', data).then(response => {
          this.optionsBis = []
          response.data.data.forEach(dataBis => {
            const item = {
              value: dataBis.id,
              text: dataBis.nama,
            }
            this.optionsBis.push(item)
          })
        })
      }
    },
  },
  created() {
    this.getBus()
    this.getSupir()
    this.getKernet()
    this.getTanggalMerah()
    if (this.type === 'bis') {
      this.disabledDates = [
        {
          start: null,
          end: null,
        },
      ]
    }
  },
  methods: {
    addBooking() {
      // if (this.type === 'tanggal') {
      //   this.data.tanggal_berangkat = this.data.tanggal.slice(0, 1).join(', ')
      //   this.data.tanggal_balik = this.data.tanggal.slice(1).join(', ')
      // } else {
      // }
      // eslint-disable-next-line prefer-destructuring
      this.data.tanggal_berangkat = this.data.tanggal.start.toISOString().split('T')[0]
      // eslint-disable-next-line prefer-destructuring
      this.data.tanggal_balik = this.data.tanggal.end.toISOString().split('T')[0]
      if (this.data.link_gmaps !== null) {
        this.data.link_gmaps_penjemputan = `https://maps.app.goo.gl/${this.data.link_gmaps}`
      }
      this.$http.post('/booking', this.data).then(response => {
        if (response.data.status === true) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: 'BellIcon',
              variant: 'success',
            },
          })

          this.$router.push({ name: 'booking' })
        }
      })
    },
    getBus() {
      this.$http.get('/bis').then(response => {
        if (response.data.status === true) {
          response.data.data.forEach(data => {
            const item = {
              value: data.id,
              text: data.nama,
            }
            this.optionsBis.push(item)
          })
        }
      })
    },
    getSupir() {
      this.$http.get('/supir-data').then(response => {
        if (response.data.status === true) {
          response.data.data.forEach(data => {
            const item = {
              value: data.id,
              text: data.nama,
            }
            this.optionsSupir.push(item)
          })
        }
      })
    },
    getKernet() {
      this.$http.get('/kernet-data').then(response => {
        if (response.data.status === true) {
          response.data.data.forEach(data => {
            const item = {
              value: data.id,
              text: data.nama,
            }
            this.optionsKernet.push(item)
          })
        }
      })
    },
    getTanggalMerah() {
      this.$http.get('/tanggal-merah').then(response => {
        this.tanggalMerah = response.data
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.mx-input {
  background-color: #293046;
  color: white;
}
</style>
